import React from "react";
import { Link } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import "./footer.scss";
import { Mixpanel } from "../mixpanel/Mixpanel";

//images
import logo from "../../assets/images/logo.png";
// import facebook from "../../assets/images/3.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
// import twitter from "../../assets/images/2.png";
import twitter from "../../assets/images/twitter.png";
import email from "../../assets/images/1.png";
// import discord from "../../assets/images/4.png";
import discord from "../../assets/images/discord.png";
import tiktok from "../../assets/images/tiktok.png";
import $ from "jquery";
import { messages } from "../messages";

const year = new Date().getFullYear();

const requestOptions = {
  method: "GET",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
};

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.openTermsAndConditions = this.openTermsAndConditions.bind(this);
    this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
    this.openHowToPlay = this.openHowToPlay.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  openTermsAndConditions() {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "adminContest/get-terms-and-condition",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.openPdfPopup(data["response"]);
          $(".loader").removeClass("show");
        } else {
          messages("error", data["message"]);
          $(".loader").removeClass("show");
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  openPrivacyPolicy() {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "adminContest/get-privacy-policy",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data["success"]) {
          this.openPdfPopup(data["response"]);
          $(".loader").removeClass("show");
        } else {
          messages("error", data["message"]);
          $(".loader").removeClass("show");
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  openHowToPlay() {
    $(".loader").addClass("show");
    fetch(
      process.env.REACT_APP_SERVER_URL + "adminContest/get-how-to-play",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data["success"]) {
          this.openPdfPopup(data["response"]);
          $(".loader").removeClass("show");
        } else {
          messages("error", data["message"]);
          $(".loader").removeClass("show");
        }
      })
      .catch((error) => {
        messages("error", error);
      });
  }

  openPdfPopup(url) {
    $(".pdf-popup").addClass("show").find("iframe").attr("src", url);

    Mixpanel.track("web:payment_deposit_info_opened");
  }

  closePdfPopup() {
    $(".pdf-popup").removeClass("show").find("iframe").attr("src", "");
  }

  handleScroll() {
    let howToPlayButton = document.getElementById("how-to-play");
    howToPlayButton.scrollIntoView();
  }

  render() {
    return (
      <footer className="landing-footer">
        <div className="pdf-popup">
          <div className="inner">
            <button
              onClick={this.closePdfPopup}
              className="close-pdf-modal"
              title="Close"
            >
              x
            </button>
            <iframe src="" frameBorder="0">
              {" "}
            </iframe>
          </div>
        </div>
        <div className="inner">
          <div className="top">
            <div className="left">
              {!localStorage.getItem("userData") ? (
                <Link
                  to={process.env.REACT_APP_START_URL}
                  title="Thrive Fantasy"
                >
                  <img src={logo} alt="Thrive Fantasy" />
                </Link>
              ) : (
                <Link
                  to={process.env.REACT_APP_START_URL + "contest-lobby"}
                  title="Thrive Fantasy"
                >
                  <img src={logo} alt="Thrive Fantasy" />
                </Link>
              )}
              <p>© {year} Whitelabel. All Rights Reserved.</p>
            </div>
            <div className="right">
              <ul>
                {/* <li>
                  <a
                    href="https://blog.thrivefantasy.com"
                    target="_blank"
                    onClick={() => Mixpanel.track("web:blog_opened")}
                  >
                    Blog
                  </a>
                </li> */}
                <Link
                  to={process.env.REACT_APP_START_URL + "how-to-play"}
                  target="_blank"
                >
                  <li>
                    <a>How To Play</a>
                  </li>
                </Link>
                <Link
                  to={process.env.REACT_APP_START_URL + "privacy-policy"}
                  target="_blank"
                >
                  <li>
                    <a>Privacy</a>
                  </li>
                </Link>
                <Link
                  to={process.env.REACT_APP_START_URL + "terms-and-conditions"}
                  target="_blank"
                >
                  <li>
                    <a>Terms</a>
                  </li>
                </Link>
                <li>
                  <a
                    onClick={() =>
                      this.openPdfPopup(
                        "https://s3.amazonaws.com/s3-p2f-dev/payment+deposit+and+refund+policy+4-11-18.txt"
                      )
                    }
                  >
                    Payment/Deposit Info
                  </a>
                </li>
                <li>
                  <div className="socialLinks">
                    <a
                      href="https://www.tiktok.com/@thriveteam"
                      target="_blank"
                      title="TikTok"
                      rel="noopener noreferrer"
                      //   onClick={() => Mixpanel.track("web:tiktok_opened")}
                    >
                      <img src={tiktok} alt="Tiktok" />
                    </a>
                    <a
                      href="https://www.instagram.com/thrivefantasy/"
                      target="_blank"
                      title="Instagram"
                      rel="noopener noreferrer"
                      //   onClick={() => Mixpanel.track("web:instagram_opened")}
                    >
                      <img src={instagram} alt="Instagram" />
                    </a>
                    <a
                      href="https://www.facebook.com/ThriveFantasy/"
                      target="_blank"
                      title="Facebook"
                      rel="noopener noreferrer"
                      onClick={() => Mixpanel.track("web:facebook_opened")}
                    >
                      <img src={facebook} alt="Facebook" />
                    </a>
                    <a
                      href="https://twitter.com/thrivefantasy"
                      target="_blank"
                      title="Twitter"
                      rel="noopener noreferrer"
                      onClick={() => Mixpanel.track("web:twitter_opened")}
                    >
                      <img src={twitter} alt="Twitter" />
                    </a>
                    <a
                      href="https://discord.gg/BsezB9C"
                      target="_blank"
                      title="Discord"
                      rel="noopener noreferrer"
                      onClick={() => Mixpanel.track("web:discord_opened")}
                    >
                      <img src={discord} alt="Discord" />
                    </a>
                    <a
                      href="mailto:admin@thrivefantasy.com"
                      title="admin@thrivefantasy.com"
                      rel="noopener noreferrer"
                      onClick={() => Mixpanel.track("web:email_opened")}
                    >
                      <img src={email} alt="admin@thrivefantasy.com" />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="bottom">
            <p id="rollover-warning">
              *Deposit match subject to a 2x rollover requirement
            </p>
            <p>
              Please contact{" "}
              <a
                href="mailto:admin@thrivefantasy.com"
                title="admin@thrivefantasy.com"
              >
                admin@thrivefantasy.com
              </a>{" "}
              for general inquiries and for support. 205 East 42nd St. Fl 15.
              NY, NY 10016
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
