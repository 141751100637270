import React from 'react';
import { Link } from "react-router-dom";
import './sign-up.scss';
import logo from "../../assets/images/loginlogo.png";
import $ from 'jquery';
import { messages } from "../messages";
import { isBrowser, isMobile } from 'react-device-detect';
import { GoogleLogin } from 'react-google-login';
import axios from 'axios';


import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { Mixpanel } from '../mixpanel/Mixpanel';

function validateEmail($email) {
    var emailReg = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailReg.test($email);
}
const requestOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            usernameInput: '',
            email: '',
            googleEmail: '',
            idToken: '',
            password: '',
            dateOfBirthBefore: '',
            dateOfBirth: '',
            address: '',
            promotionalCode: '',
            deviceID: null,
            deviceToken: null,
            devicetype: "3",
            utm_source: window.localStorage.getItem('utm_source'),
            utm_campaign: window.localStorage.getItem('utm_campaign'),
            linkPromo: window.localStorage.getItem('promo'),
            redirectLink: window.localStorage.getItem('redirectLink'),
            fantasyAlarmId: window.localStorage.getItem('fantasyAlarmId'),
            rotogrindersId: window.localStorage.getItem("rotogrindersId"),
            fantasyProsId: window.localStorage.getItem('fantasyProsId'),
            iabtag: window.localStorage.getItem("iabtag"),
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.openTermsAndConditions = this.openTermsAndConditions.bind(this);
        this.openPrivacyPolicy = this.openPrivacyPolicy.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.showUsernameModal = this.showUsernameModal.bind(this);
        this.closeUsernameModal = this.closeUsernameModal.bind(this);
        this.usernameSubmit = this.usernameSubmit.bind(this);
        this.handleUsernameInput = this.handleUsernameInput.bind(this);

        this.getId = this.getId.bind(this);
        this.saveRotogrindersId = this.saveRotogrindersId.bind(this);
        this.saveFantasyProsId = this.saveFantasyProsId.bind(this);
        this.getIABtag = this.getIABtag.bind(this);
        this.collectQueryParameters = this.collectQueryParameters.bind(this);
    }

    componentDidMount() {
        // console.log("SIGN UP REDIRECT: ", window.localStorage.getItem('redirectLink'));
        // console.log("SIGN UP REDIRECT: ", window.localStorage.getItem('redirectLink'));
        Mixpanel.track('web:sign_up_opened')

        console.log("FA ID: " + this.state.fantasyAlarmId)

        const queryStringHash = this.collectQueryParameters();
        this.getIABtag(queryStringHash);

    }


    collectQueryParameters() {
        const searchString = window.location.search;
        if (searchString.length === 0) {
            return false;
        }
        if (!searchString.startsWith("?")) {
            return false;
        }
        const queryString = searchString.slice(1, searchString.length); // take out leading question mark
        const queryStringArray = queryString.split("&");
        const queryStringHash = {};
        for (let i = 0; i < queryStringArray.length; i++) {
            const queryStringTerm = queryStringArray[i];
            const queryStringTermArray = queryStringTerm.split("=");
            queryStringHash[queryStringTermArray[0]] = queryStringTermArray[1];
        }

        return queryStringHash;
    }


    // If users are routed to home page via IA campaign, will have a 'btag' query parameter
    // Run through query string and create a hash of all params in query string, in case later on more params are needed
    // Pull out 'btag' param, save to localStorage for use in sign-up process
    getIABtag(queryStringHash) {
        if (queryStringHash) {
            if (queryStringHash["btag"]) {
                window.localStorage.setItem("iabtag", queryStringHash["btag"]);
                this.setState({ iabtag: queryStringHash["btag"] });
            }
        }
    }


    async saveRotogrindersId(email) {
        // console.log("ROTO: " + this.state.rotogrindersId + " --------- " + email)

        const reqLink = `${process.env.REACT_APP_SERVER_URL}rotogrinders/addRotogrindersId/` + this.state.rotogrindersId  + "/" + email // serverless link here in service-rotogrinders
        window.localStorage.setItem("rotogrindersLink", reqLink);
    }

    async saveFantasyProsId(email) {
        // const reqLink = "https://skynet.thrivefantasy.com/addFantasyProsId/" + this.state.fantasyProsId  + "/" + email
        // const reqLink = `${process.env.REACT_APP_LAMBDA_COMBO_URL}fantasyPros/addFantasyProsId/` + this.state.fantasyProsId  + "/" + email
        const reqLink = `${process.env.REACT_APP_SERVER_URL}fantasyPros/addFantasyProsId/` + this.state.fantasyProsId  + "/" + email
        window.localStorage.setItem("fantasyProsLink", reqLink);
        console.log('Fantasy Pros Link set to: ' + reqLink);
    }


    async getId(email) {
        console.log(this.state.fantasyAlarmId + " --------- " + email)

        // const reqLink = `${process.env.REACT_APP_LAMBDA_COMBO_URL}fantasy-alarm/addFantasyAlarmId/` + this.state.fantasyAlarmId + '/' + email
        const reqLink = `${process.env.REACT_APP_SERVER_URL}fantasy-alarm/addFantasyAlarmId/` + this.state.fantasyAlarmId + '/' + email

        // console.log(reqLink)

        window.localStorage.setItem("fantasyAlarmLink", reqLink)
        // await axios.get(reqLink).then(res => {
        //     console.log(res)
        // }
        // ).catch(err => {
        //     console.log(err)
        // })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleUsernameInput(event) {
        const target = event.target;
        const value = target.value;
        // const name = target.name;

        this.setState({
            usernameInput: value
        });
    }

    showUsernameModal() {
        $('.username-modal').addClass('show');
    }

    usernameSubmit() {
        // this.state.usernameInput

        Mixpanel.track('web:google_username_entered')

        let case1SubmitBody = {
            email: this.state.googleEmail,
            username: this.state.usernameInput,
            idToken: this.state.idToken,
            devicetype: '3',
            promotionalCode: this.state.promotionalCode
        }

        const case1Options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify(case1SubmitBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'signupG', case1Options)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    try {
                        window.signupScript(document, "script")
                    } catch (error) {
                        console.error(error);
                    }
                    window.localStorage.setItem('userData', JSON.stringify(data['response']));
                    $('.loader').removeClass('show');

                    if (window.localStorage.getItem('redirectLink') === null || window.localStorage.getItem('redirectLink') === 'null' || window.localStorage.getItem('redirectLink') === '') {
                        if(this.state.promotionalCode === "ALARM" || this.state.promotionalCode === "alarm") {
                            this.getId(this.state.googleEmail)
                        }
                        else if(this.state.promotionalCode === "grinderssub" || this.state.promotionalCode === "GRINDERSSUB") {
                            this.saveRotogrindersId(this.state.googleEmail)
                        }
                        else if(this.state.promotionalCode === 'fprossub' || this.state.promotionalCode === 'FPROSSUB') {
                            console.log('Calling save fantasy pros id');
                            this.saveFantasyProsId(this.state.googleEmail)
                        }

                        window.location.href = process.env.REACT_APP_START_URL + "completed-registration/wallet";
                    }
                    else {
                        if(this.state.promotionalCode === "ALARM" || this.state.promotionalCode === "alarm") {
                            this.getId(this.state.googleEmail)
                        }
                        else if(this.state.promotionalCode === "grinderssub" || this.state.promotionalCode === "GRINDERSSUB") {
                            this.saveRotogrindersId(this.state.googleEmail)
                        }
                        else if(this.state.promotionalCode === 'fprossub' || this.state.promotionalCode === 'FPROSSUB') {
                            console.log('Calling save fantasy pros id');
                            this.saveFantasyProsId(this.state.googleEmail)
                        }
                        window.location.href = process.env.REACT_APP_START_URL + window.localStorage.getItem('redirectLink');
                    }
                }
                else {
                    $('.server-error').show().html(data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    closeUsernameModal() {
        $('.username-modal').removeClass('show');
        Mixpanel.track('web:google_username_closed')
    }

    responseGoogle = (googleResponse) => {
        console.log("HERE " + JSON.stringify(googleResponse))
        let requestBody = {
            email: googleResponse.profileObj.email,
        }
        const googleSignInOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify(requestBody)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'signupGoogle', googleSignInOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    if (data['response'] === 1) {
                        this.setState({
                            googleEmail: googleResponse.profileObj.email,
                            idToken: googleResponse.tokenId
                        });
                        this.showUsernameModal();
                    }
                    if (data['response'] === 2) {
                        $('.server-error').show().html('This User Exists But Is Not A Google Account');
                    }
                    if (data['response'] === 3) {
                        let loginReqBody = {
                            email: googleResponse.profileObj.email,
                            password: null,
                            deviceID: '',
                            deviceToken: '',
                            devicetype: "3",
                            idToken: googleResponse.tokenId,
                        }
                        const case3Options = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
                            body: JSON.stringify(loginReqBody)
                        };
                        fetch(process.env.REACT_APP_SERVER_URL + 'login', case3Options)
                            .then(response => response.json())
                            .then(data => {
                                if (data['success']) {
                                    window.localStorage.setItem('userData', JSON.stringify(data['response']));
                                    window.location.href = process.env.REACT_APP_START_URL + "contest-lobby";
                                }

                                const eventProps = { 'Provider': 'Google' };

                                if (this.state.promotionalCode) {
                                  eventProps['Promotional code'] = this.state.promotionalCode;
                                }
                                else {
                                    $('.server-error').show().html(data['message']);
                                }

                                Mixpanel.identify(data['response']['userid']);
                                Mixpanel.track('web:user_signed_up', eventProps);
                                // Mixpanel.people.set({
                                //   '$first_name': user['userName'],
                                //   '$email': user['email']
                                // });


                            })
                    }
                }
            });
    }

    handleSubmit(event) {

        $('.loader').addClass('show');
        event.preventDefault();

        const username = this.state.username;
        const email = this.state.email;
        const password = this.state.password;
        const dateOfBirthBefore = this.state.dateOfBirth.slice(5, 7) + '/' + this.state.dateOfBirth.slice(8, 10) + '/' + this.state.dateOfBirth.slice(0, 4)

        console.log(this.state);

        const tempState = JSON.parse(JSON.stringify(this.state))
        tempState.dateOfBirth = this.state.dateOfBirth;

        console.log('this.state - ' + this.state.dateOfBirth)
        console.log('tempstate - ' + tempState.dateOfBirth)

        let error = false;
        $('input').removeClass('error').next('.error').hide().html('');

        if (username === '') {
            $('input[name="username"]').addClass('error').next('.error').show().html('Username is required.');
            error = true;
        }

        if (email === '') {
            $('input[name="email"]').addClass('error').next('.error').show().html('Email is required.');
            error = true;
        }

        if (!validateEmail(email)) {
            $('input[name="email"]').addClass('error').next('.error').show().html('Invalid email.');
            error = true;
        }


        if (password.length < 6) {
            $('input[name="password"]').addClass('error').next('.error').show().html('Password length must be at least 6 characters.');
            error = true;
        }

        // if (this.state.dateOfBirth == '') {
        //     $('input[name="dateOfBirth"]').addClass('error').next('.error').show().html('Date of Birth is required.');
        //     error = true;
        // }

        // if (this.state.address == '') {
        //     $('input[name="address"]').addClass('error').next('.error').show().html('Address is required.');
        //     error = true;
        // }

        if (error) {
            $('.loader').removeClass('show');
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
            body: JSON.stringify(tempState)
        };

        fetch(process.env.REACT_APP_SERVER_URL + 'signup', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    try {
                        window.signupScript(document, "script")
                    } catch (error) {
                        console.error(error);
                    }

                    const promoCodeComparison = "" + tempState.promotionalCode
                    if (promoCodeComparison.toLowerCase() === "chargers" || promoCodeComparison.toLowerCase() === "charger") {
                        console.log('we in here');
                        window.gtag_report_conversion()
                    }

                    window.localStorage.setItem('userData', JSON.stringify(data['response']));

                    const user = data['response']['userDto'];
                        Mixpanel.identify(user['userid']);
                    Mixpanel.track('web:user_signed_up', (
                      tempState.promotionalCode ? { 'Promotional code': tempState.promotionalCode } : {}
                    ));
                    Mixpanel.people.set({
                        '$first_name': user['userName'],
                        '$email': user['email']
                    })

                    $('.loader').removeClass('show');
                    if (window.localStorage.getItem('redirectLink') === null || window.localStorage.getItem('redirectLink') === 'null' || window.localStorage.getItem('redirectLink') === '') {
                        if(this.state.promotionalCode === "ALARM" || this.state.promotionalCode === "alarm") {
                            this.getId(user['email'])
                        }
                        else if(this.state.promotionalCode === "grinderssub" || this.state.promotionalCode === "GRINDERSSUB") {
                            this.saveRotogrindersId(user['email'])
                        }
                        else if(this.state.promotionalCode === 'fprossub' || this.state.promotionalCode === 'FPROSSUB') {
                            console.log('Calling save fantasy pros id');
                            this.saveFantasyProsId(user['email']);
                        }

                        window.location.href = process.env.REACT_APP_START_URL + "completed-registration/wallet";
                    }
                    else {
                        if(this.state.promotionalCode === "ALARM" || this.state.promotionalCode === "alarm") {
                            this.getId(user['email'])
                        }
                        else if(this.state.promotionalCode === "grinderssub" || this.state.promotionalCode === "GRINDERSSUB") {
                            this.saveRotogrindersId(user['email'])
                        }
                        else if(this.state.promotionalCode === 'fprossub' || this.state.promotionalCode === 'FPROSSUB') {
                            console.log('Calling save fantasy pros id');
                            this.saveFantasyProsId(user['email']);
                        }
                        window.location.href = process.env.REACT_APP_START_URL + window.localStorage.getItem('redirectLink');
                    }
                } else {
                    $('.server-error').show().html(data['message']);
                    $('.loader').removeClass('show');
                }
            });
    }

    openTermsAndConditions() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-terms-and-condition', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
            messages('error', error);
        });
    }

    openPrivacyPolicy() {
        $('.loader').addClass('show');
        fetch(process.env.REACT_APP_SERVER_URL + 'adminContest/get-privacy-policy', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data['success']) {
                    this.openPdfPopup(data['response']);
                    $('.loader').removeClass('show');
                } else {
                    messages('error', data['message']);
                    $('.loader').removeClass('show');
                }
            }).catch((error) => {
            messages('error', error);
        });

    }

    openPdfPopup(url) {
        $('.pdf-popup').addClass('show').find('iframe').attr('src', url);
    }

    closePdfPopup() {
        $('.pdf-popup').removeClass('show').find('iframe').attr('src', '');
    }

    handlePromo() {
        if (this.state.linkPromo === "" || this.state.linkPromo == null || this.state.linkPromo === 'undefined' || this.state.linkPromo === undefined) {
            console.log("HERRE", this.state.linkPromo)
            return (
                <li>
                    <input className="field-input" type="text" name="promotionalCode" placeholder="Promo code" onChange={this.handleInputChange} />
                </li>
            )
        } else if (this.state.promotionalCode === null || this.state.promotionalCode === "") {
            this.state.promotionalCode = this.state.linkPromo;
            console.log("promotional code set to: " + this.state.promotionalCode)
        }
    }

    /* <li>
        <input className="field-input" type="text" name="address" placeholder="Address" onChange={this.handleInputChange} />
        <span className="error"></span>
    </li> */

    render() {

        const privacyButton = (isBrowser)
            ? <button title="Privacy Policy" onClick={this.openPrivacyPolicy}>Privacy Policy</button>
            : <button title="Privacy Policy"> <a target="_blank" href={"https://s3.amazonaws.com/s3-p2f-dev/privacyPolicy/PrivacyPolicy2019.pdf"}>Privacy Policy</a></button>;
        const termsButton = (isBrowser)
            ? <button title="Terms & Conditions" onClick={this.openTermsAndConditions}>Terms & Conditions </button>
            : <button title="Terms & Conditions"> <a target="_blank" href={"https://s3.amazonaws.com/s3-p2f-dev/pdf/TermsAndConditions2019.pdf"}>Terms & Conditions</a></button>

        return (
            <main className="Login-page">
                <section className="left">
                    <Link to={process.env.REACT_APP_START_URL} title="Thrive Fantasy">
                        <img src={logo} alt="Thrive Fantasy" />
                    </Link>
                    <h1><strong>Welcome!</strong></h1>
                </section>
                <section className="right">
                    <div style={{ marginTop: '75px' }}>
                        <h1>Getting Started</h1>
                        <form onSubmit={this.handleSubmit}>
                            <ul>
                                <li>
                                    <input className="field-input" type="text" name="username" placeholder="Username" onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>
                                <li>
                                    <input className="field-input" type="text" name="email" placeholder="Email" onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>
                                <li>
                                    <input className="field-input" type="password" name="password" placeholder="Password" onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>

                                <li>
                                    <input className='field-input'
                                           type="text"
                                           name='dateOfBirth'
                                           minLength='10'
                                           maxLength='10'

                                           placeholder="Date of Birth (MM/DD/YYYY)"
                                           onChange={this.handleInputChange} />
                                    <span className="error"></span>
                                </li>
                                {this.handlePromo()}
                                <li>
                                    <input type="submit" value="Sign Up" />
                                </li>
                            </ul>
                        </form>
                        <p className="server-error">

                        </p>
                        <p className="text-nav">
                            Already a member? <Link to={process.env.REACT_APP_START_URL + "login"}>Sign In</Link>
                        </p>
                        <p>
                            By signing up, you agree to ThriveFantasy’s <br /> {termsButton} and {privacyButton}
                        </p>
                        <GoogleLogin
                            clientId="952828972098-i7ddorimqhdcgp7gp3n8rs7dqa42pipv.apps.googleusercontent.com"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            onClick={Mixpanel.track('web:google_pop_up_clicked')}> Sign In/Up With Google
                        </GoogleLogin>
                    </div>
                </section>
                <div className="pdf-popup">
                    <div className="inner">
                        <button onClick={this.closePdfPopup} className="close-pdf-modal" title="Close">x</button>
                        <iframe src="" frameBorder="0" > </iframe>
                    </div>
                </div>
                <div className="username-modal">
                    <div className="inner">
                        <button onClick={this.closeUsernameModal} className="close-username-modal close" title="Close"></button>
                        <h2>Choose a Username</h2>
                        <div>
                            <div>
                                <label>Please Create a Username to Complete Registration</label>
                                <input name="usernamePick" onChange={this.handleUsernameInput} className="username-pick" style={{ width: 300 }} />
                            </div>

                        </div>
                        <button onClick={this.usernameSubmit} className="submit-username-now" title="Pay Now">Register</button>

                    </div>

                </div>
            </main>
        );
    }
}


export default SignUp;
