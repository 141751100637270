import React from 'react';
import { withRouter } from 'react-router-dom';
import './props-lobby.scss';
import LoggedInHeader from '../Logged-in-header/Logged-in-header';
import $, { event } from 'jquery';
import Moment from 'react-moment';
import { messages } from '../messages';
import { isAuthenticated } from '../isAuthenticated';
import Footer from '../footer/footer';
import { useJsonToCsv } from 'react-json-csv';
import PlayerStatsPopup from '../player-stats-popup/player-stats-popup';
import { Mixpanel } from '../mixpanel/Mixpanel';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import beltImage from "../../assets/images/belt_render.jpg";

const MenuItem = ({ text, selectedSport }) => {
  return (
      <div className={`menu-item ${selectedSport ? 'active' : ''}`}>{text}</div>
  );
};

const Arrow = ({ text, className }) => {
  return <div className={className}> {text}</div>;
};

const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

export const Menu = (list, selected) =>
    list.map((el) => {
      const { name } = el;

      return <MenuItem text={name} key={name} selected={selected} />;
    });

const sportsProps = {
  NBA: [
    "PTS",
    "ASTS",
    "REBS",
    "PTS + ASTS",
    "PTS + REBS",
    "REBS + ASTS",
    "PTS + REBS + ASTS",
  ],
  NFL: [
    "Pass YDS",
    "Rush YDS",
    "Rec YDS",
    "Rush YDS + Rec YDS",
    "Pass YDS + Rush YDS",
    "Pass TD's",
    "CMP",
    "INT",
    "REC",
    "PTS",
  ],
  HOCKEY: ["GOLs", "ASTs", "SAVs", "GOLs + ASTs"],
  SOCCER: ["GOLs", "ASTs", "SAVs", "GOLs + ASTs"],
  // ESPORTS: [(CS:GO, Dota 2, LoL, VALORANT) - Kills, Deaths, Assists, Headshots, Kills + Assists, Kills+Deaths, Deaths+Assists, Kills + Deaths + Assists (Only VAL and CSGO have headshots)],
  MLB: ["HITs", "RBIs", "BASEs", "Ks", "RUNs"],
  GOLF: ["STRs", "HIOs", "EAGs", "BRDs", "PARs", "BOGs"],
  UFC: ["CTL Time", "KDs", "Sig Str", "Sig Str ATT", "Sig Str %", "Sub ATT", "TDs", "TDs ATT", "TD %", "BOD Str", "HEAD Str", "LEG Str"]
};

class PropsLobby extends React.Component {
  constructor(props) {
    super(props);
    // this.menuItems = Menu(list, selected);
    this.state = {
      // allOfTheProps: {},
      allHousePropsMap: new Map(),
      // propsList: [],
      currentlyShowingHousePropsMap: new Map(),
      // firstHalfProps: [],
      // secondHalfProps: [],
      // housePropsDictionary: [],
      multipleFor2Props: 0,
      multipleFor3Props: 0,
      multipleFor4Props: 0,
      multipleFor5Props: 0,
      numberOfPropsPicked: 0,
      maximumPicks: 5,
      minimumPicks: 2,
      // selectedHouseProps: [],
      multiplier: 0,
      entryAmount: 1,
      // payData: {},
      selectedSport: null,
      selectedGame: null,
      selectedProp: null,
      leagueTypeList: [],//need
      gameList: [],//need
      propsList: [],
      // gamesStringList: [],
      // gameSportPairs: [],
      // activeGames: [],
      houseTickets: [],

      hasEligibleHouseTicket: false,
      toggle: null,
      gameToggle: null,
      propToggle: null,
      // latitude: null,
      // longitude: null,
      // newSport: null,

      // selectedProps: [],
      // selectedPlayerIds: [],
      // miniProps: [],

      // propsToShow: [],
      // idsToShow: [],
      // selectedPropIds: [],
    };
    $('.loader').addClass('show');
    this.fetchPropsData = this.fetchPropsData.bind(this);
    this.fetchUpcomingHousePropsByHalf = this.fetchUpcomingHousePropsByHalf.bind(this);
    this.fetchPropsDataFirstHalf = this.fetchPropsDataFirstHalf.bind(this);
    this.fetchSecondHalfPropsData = this.fetchSecondHalfPropsData.bind(this);
    this.pointsSelect = this.pointsSelect.bind(this);
    this.highlightButtonNEW = this.highlightButtonNEW.bind(this);
    this.exportPropsPicks = this.exportPropsPicks.bind(this);
    this.exportPropsPicks = this.exportPropsPicks.bind(this);
    this.clearAllPropsPicksSelectionNEW = this.clearAllPropsPicksSelectionNEW.bind(this);
    this.submitPropsPicks = this.submitPropsPicks.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.pay = this.pay.bind(this);
    this.tabSwitch = this.tabSwitch.bind(this);
    this.playerStats = React.createRef();
    this.getGame = this.getGame.bind(this);
    this.checkEligibleHouseTicket = this.checkEligibleHouseTicket.bind(this);
    this.getUserHouseTickets = this.getUserHouseTickets.bind(this);
    this.getHouseTicketDollarAmount = this.getHouseTicketDollarAmount.bind(this);
    this.getHouseTicketExpirationDate = this.getHouseTicketExpirationDate.bind(this);
    this.enterWithHouseTicket = this.enterWithHouseTicket.bind(this);
    this.getSelectedHouseProps = this.getSelectedHouseProps.bind(this);
    this.getSelectedSubmissionOdds = this.getSelectedSubmissionOdds.bind(this);
    this.getSubmissionOddsWithNumberOfProps = this.getSubmissionOddsWithNumberOfProps.bind(this);
  }

  onSelectSport = (key) => {
    if(key === 'NHL') {
      key = 'HOCKEY'
    }

    if (key !== this.state.selectedSport) {
      var gameList = [];

      let currentlyShowingHousePropsMap = this.state.currentlyShowingHousePropsMap

      let propParameterList = []

      for (const houseProp of currentlyShowingHousePropsMap.values()) {
        if (houseProp.player1.leagueType !== 'GOLF') {
          let game = this.getGame(houseProp)

          if (houseProp.player1.leagueType === key) {
            const propParameter = houseProp.player1.propParameters.join(' + ')
            if (!propParameterList.includes(propParameter)) {
              propParameterList.push(propParameter)
            }
            if (!gameList.some(function (o) {return o['name'] === game;})) {
              gameList.push({ name: game });
            }
          }
        }
      }

      var propsList = [];

      for (const propParameter of sportsProps[key]) {
        if (propParameterList.includes(propParameter)) {
          propsList.push({name: propParameter})
        }
      }

      this.setState({
        gameList: gameList,
        propsList: propsList,
        selectedSport: key,
        selectedGame: null,
        selectedProp: null,
        toggle: true,
        gameToggle: false,
        propToggle: false,
      });
    } else {
      this.setState({
        gameList: [],
        propsList: [],
        selectedSport: null,
        selectedGame: null,
        selectedProp: null,
        toggle: false,
        gameToggle: false,
        propToggle: false,
      });
    }
  };

  onSelectGame = (key) => {

    let currentlyShowingHousePropsMap = this.state.currentlyShowingHousePropsMap
    let selectedSport = this.state.selectedSport
    let selectedGame = this.state.selectedGame
    let selectedProp = this.state.selectedProp
    let propToggle = this.state.propToggle


    console.log('selectedProp:', selectedProp);
    console.log('propToggle:', propToggle);


    var propsList = [];
    let propParameterList = []

    if (key !== selectedGame) {
      for (const houseProp of currentlyShowingHousePropsMap.values()) {
        if (houseProp.player1.leagueType === selectedSport) {
          let game = this.getGame(houseProp)
          if (game === key) {
            const propParameter = houseProp.player1.propParameters.join(' + ')
            if (!propParameterList.includes(propParameter)) {
              propParameterList.push(propParameter)
            }
          }
        }
      }
      for (const propParameter of sportsProps[selectedSport]) {
        if (propParameterList.includes(propParameter)) {
          propsList.push({name: propParameter})
        }
      }

      //if click on game, check the props, if selectedProp exists and is not part of list, remove selectedProp
      var showSelectedProp = false

      if (selectedProp) {
        if (propsList.some(function (o) {return o['name'] === selectedProp;})) {
          showSelectedProp = true
        } else {
          selectedProp = null
        }
      }

      this.setState({
        selectedGame: key,
        selectedProp: selectedProp,
        propsList: propsList,
        gameToggle: true,
        propToggle: showSelectedProp,
      });
    }
    else {
      for (const houseProp of currentlyShowingHousePropsMap.values()) {
        if (houseProp.player1.leagueType === selectedSport) {
          const propParameter = houseProp.player1.propParameters.join(' + ')
          if (!propParameterList.includes(propParameter)) {
            propParameterList.push(propParameter)
          }
        }
      }
      for (const propParameter of sportsProps[selectedSport]) {
        if (propParameterList.includes(propParameter)) {
          propsList.push({name: propParameter})
        }
      }

      this.setState({
        selectedGame: null,
        propsList: propsList,
        gameToggle: false,
      });
    }
  };

  onSelectProp = (key) => {

    let currentlyShowingHousePropsMap = this.state.currentlyShowingHousePropsMap
    let selectedSport = this.state.selectedSport
    let selectedGame = this.state.selectedGame
    let selectedProp = this.state.selectedProp

    if (key !== selectedProp) {
      this.setState({
        // gameList: gameList,
        // propsList: propsList,
        selectedProp: key,
        // selectedGame: null,
        // toggle: true,
        propToggle: true,
      });
    } else {
      this.setState({
        // gameList: [],
        // propsList: [],
        selectedProp: null,
        // selectedGame: null,
        // toggle: false,
        propToggle: false,
      });
    }
  };

  fetchPropsData() {
    //nelsonhere fetch full game
    $('.loader').addClass('show');

    navigator.geolocation.getCurrentPosition((position) => {

      let requestPropsBody = {
        currentPage: 1,
        currentSize: 100,
        half: 0,
        Latitude: position.coords.latitude + '',
        Longitude: position.coords.longitude + '',
      };

      const requestPropsOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Token: window.localStorage.getItem('userData')
              ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
              : false,
        },
        body: JSON.stringify(requestPropsBody),
      };

      fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/upcomingHouseProps', requestPropsOptions)
          .then((response) => response.json())
          .then((data) => {
            console.log('message: ' + data['message']);
            if (data['success']) {
              let allHousePropsMap = this.state.allHousePropsMap;

              let currentlyShowingHousePropsMap = new Map()
              const listData = data['response']['data'];

              let multipleFor2Props = 0;
              let multipleFor3Props = 0;
              let multipleFor4Props = 0;
              let multipleFor5Props = 0;

              let leagueTypeList = [];

              listData.forEach((singleData) => {
                let houseProp = singleData['contestProp'];
                multipleFor2Props = singleData['multipleFor2Props'];
                multipleFor3Props = singleData['multipleFor3Props'];
                multipleFor4Props = singleData['multipleFor4Props'];
                multipleFor5Props = singleData['multipleFor5Props'];


                if (houseProp.half === 0 || houseProp.half === null) {
                  let leagueType = houseProp['player1'].leagueType;

                  const tempHouseProp = {
                    contestId: houseProp['contestId'],
                    minPropsToPick: houseProp['minPropsToPick'],
                    player1: {
                      firstName: houseProp['player1'].firstName,
                      height: houseProp['player1'].height,
                      image: houseProp['player1'].image,
                      lastName: houseProp['player1'].lastName,
                      leagueType: leagueType,
                      playerId: houseProp['player1'].playerId,
                      positionAbbreviation: houseProp['player1'].positionAbbreviation,
                      uniform: houseProp['player1'].uniform,
                      weight: houseProp['player1'].weight,
                      id: houseProp['player1'].id,
                      teamAbbr: houseProp['player1'].teamAbbr,
                      propParameters: houseProp['player1'].propParameters,
                    },
                    player1TeamId: houseProp.player1TeamId,
                    propId: houseProp.propId,
                    propValue: houseProp.propValue,
                    startTime: houseProp.startTime,
                    startDate: houseProp.startTime,
                    statEventId: houseProp.statEventId,
                    team1Abbr: houseProp.team1Abbr,
                    team2Abbr: houseProp.team2Abbr,
                    venueState: houseProp.venueState,
                    team1locationType: houseProp.team1locationType,
                    golfRound: houseProp.golfRound,
                    golfTeeTime: houseProp.golfTeeTime,
                    selected: false,
                    isOverSelected: false,
                    isUnderSelected: false,
                    isPromo: houseProp.isPromo
                  }

                  if (!allHousePropsMap.has(houseProp.propId)) {
                    allHousePropsMap.set(houseProp.propId, tempHouseProp)
                  }
                  if (!leagueTypeList.some(function (o) {return o['name'] === leagueType;})) {
                    leagueTypeList.push({
                      name: leagueType,
                    });
                  }
                  currentlyShowingHousePropsMap.set(houseProp.propId, tempHouseProp)
                }
              });
              this.setState({
                allHousePropsMap: allHousePropsMap,
                currentlyShowingHousePropsMap: currentlyShowingHousePropsMap,
                multipleFor2Props: multipleFor2Props,
                multipleFor3Props: multipleFor3Props,
                multipleFor4Props: multipleFor4Props,
                multipleFor5Props: multipleFor5Props,
                leagueTypeList: leagueTypeList,
              });
              $('.loader').removeClass('show');
            } else {
              $('.loader').removeClass('show');
              messages('error', data['message']);
            }
          });
    });
  }

  fetchPropsDataFirstHalf(requestPropsOptionsFirstHalf) {
    $('.loader').addClass('show');
    fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/upcomingHouseProps', requestPropsOptionsFirstHalf)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {
            let allHousePropsMap = this.state.allHousePropsMap;
            let currentlyShowingHousePropsMap = new Map()
            const listData = data['response']['data'];
            let multipleFor2Props = 0;
            let multipleFor3Props = 0;
            let multipleFor4Props = 0;
            let multipleFor5Props = 0;

            let leagueTypeList = [];

            listData.forEach((singleData) => {
              let houseProp = singleData['contestProp'];
              multipleFor2Props = singleData['multipleFor2Props'];
              multipleFor3Props = singleData['multipleFor3Props'];
              multipleFor4Props = singleData['multipleFor4Props'];
              multipleFor5Props = singleData['multipleFor5Props'];


              if (houseProp.half === 1) {
                let leagueType = houseProp['player1'].leagueType;

                const tempHouseProp = {
                  contestId: houseProp['contestId'],
                  minPropsToPick: houseProp['minPropsToPick'],
                  player1: {
                    firstName: houseProp['player1'].firstName,
                    height: houseProp['player1'].height,
                    image: houseProp['player1'].image,
                    lastName: houseProp['player1'].lastName,
                    leagueType: leagueType,
                    playerId: houseProp['player1'].playerId,
                    positionAbbreviation: houseProp['player1'].positionAbbreviation,
                    uniform: houseProp['player1'].uniform,
                    weight: houseProp['player1'].weight,
                    id: houseProp['player1'].id,
                    teamAbbr: houseProp['player1'].teamAbbr,
                    propParameters: houseProp['player1'].propParameters,
                  },
                  player1TeamId: houseProp.player1TeamId,
                  propId: houseProp.propId,
                  propValue: houseProp.propValue,
                  startTime: houseProp.startTime,
                  startDate: houseProp.startTime,
                  statEventId: houseProp.statEventId,
                  team1Abbr: houseProp.team1Abbr,
                  team2Abbr: houseProp.team2Abbr,
                  venueState: houseProp.venueState,
                  team1locationType: houseProp.team1locationType,
                  golfRound: houseProp.golfRound,
                  golfTeeTime: houseProp.golfTeeTime,
                  selected: false,
                  isOverSelected: false,
                  isUnderSelected: false,
                  isPromo: houseProp.isPromo
                }

                if (!allHousePropsMap.has(houseProp.propId)) {
                  allHousePropsMap.set(houseProp.propId, tempHouseProp)
                }

                if (!leagueTypeList.some(function (o) {return o['name'] === leagueType;})) {
                  leagueTypeList.push({
                    name: leagueType,
                  });
                }
                currentlyShowingHousePropsMap.set(houseProp.propId, tempHouseProp)
              }
            });

            this.setState({
              allHousePropsMap: allHousePropsMap,
              currentlyShowingHousePropsMap: currentlyShowingHousePropsMap,
              multipleFor2Props: multipleFor2Props,
              multipleFor3Props: multipleFor3Props,
              multipleFor4Props: multipleFor4Props,
              multipleFor5Props: multipleFor5Props,

              leagueTypeList: leagueTypeList,
            });

            $('.loader').removeClass('show');
          } else {
            $('.loader').removeClass('show');
          }
        });
  }


  fetchUpcomingHousePropsByHalf(requestUpcomingHousePropsOptions) {
    console.log('Enter fetchUpcomingHousePropsByHalf')
    fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/upcomingHouseProps', requestUpcomingHousePropsOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {

            let allHousePropsMap = this.state.allHousePropsMap;
            let currentlyShowingHousePropsMap = new Map()
            const listData = data['response']['data'];
            let multipleFor2Props = 0;
            let multipleFor3Props = 0;
            let multipleFor4Props = 0;
            let multipleFor5Props = 0;


            let leagueTypeList = [];

            listData.forEach((singleData) => {
              let houseProp = singleData['contestProp'];
              let leagueType = houseProp['player1'].leagueType;

              const tempHouseProp = {
                contestId: houseProp['contestId'],
                minPropsToPick: houseProp['minPropsToPick'],
                player1: {
                  firstName: houseProp['player1'].firstName,
                  height: houseProp['player1'].height,
                  image: houseProp['player1'].image,
                  lastName: houseProp['player1'].lastName,
                  leagueType: leagueType,
                  playerId: houseProp['player1'].playerId,
                  positionAbbreviation: houseProp['player1'].positionAbbreviation,
                  uniform: houseProp['player1'].uniform,
                  weight: houseProp['player1'].weight,
                  id: houseProp['player1'].id,
                  teamAbbr: houseProp['player1'].teamAbbr,
                  propParameters: houseProp['player1'].propParameters,
                },
                player1TeamId: houseProp.player1TeamId,
                propId: houseProp.propId,
                propValue: houseProp.propValue,
                startTime: houseProp.startTime,
                startDate: houseProp.startTime,
                statEventId: houseProp.statEventId,
                team1Abbr: houseProp.team1Abbr,
                team2Abbr: houseProp.team2Abbr,
                team1Name: houseProp.adminEvent.team1Name,
                team2Name: houseProp.adminEvent.team2Name,
                venueState: houseProp.venueState,
                team1locationType: houseProp.team1locationType,
                golfRound: houseProp.golfRound,
                golfTeeTime: houseProp.golfTeeTime,
                selected: false,
                isOverSelected: false,
                isUnderSelected: false,
                isPromo: houseProp["isPromo"]
              }

              if (!allHousePropsMap.has(houseProp.propId)) {
                allHousePropsMap.set(houseProp.propId, tempHouseProp)
              }


              currentlyShowingHousePropsMap.set(houseProp.propId, tempHouseProp)
              // propsList.push(tempHouseProp);

              multipleFor2Props = singleData['multipleFor2Props'];
              multipleFor3Props = singleData['multipleFor3Props'];
              multipleFor4Props = singleData['multipleFor4Props'];
              multipleFor5Props = singleData['multipleFor5Props'];

              if (
                  !leagueTypeList.some(function (o) {
                    return o['name'] === leagueType;
                  })
              ) {
                leagueTypeList.push({
                  name: leagueType,
                });
              }
            });

            this.setState({
              allHousePropsMap: allHousePropsMap,
              currentlyShowingHousePropsMap: currentlyShowingHousePropsMap,
              multipleFor2Props: multipleFor2Props,
              multipleFor3Props: multipleFor3Props,
              multipleFor4Props: multipleFor4Props,
              multipleFor5Props: multipleFor5Props,

              leagueTypeList: leagueTypeList,
            });

            $('.loader').removeClass('show');
          } else {
            $('.loader').removeClass('show');
          }
        });
    console.log('Finished fetchUpcomingHousePropsByHalf')
  }

  fetchSecondHalfPropsData(requestPropsOptionsSecondHalf) {
    $('.loader').addClass('show');
    fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/upcomingHouseProps', requestPropsOptionsSecondHalf)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {
            let allHousePropsMap = this.state.allHousePropsMap;
            let currentlyShowingHousePropsMap = new Map()
            const listData = data['response']['data'];
            let multipleFor2Props = 0;
            let multipleFor3Props = 0;
            let multipleFor4Props = 0;
            let multipleFor5Props = 0;

            let leagueTypeList = [];

            listData.forEach((singleData) => {
              let houseProp = singleData['contestProp'];
              multipleFor2Props = singleData['multipleFor2Props'];
              multipleFor3Props = singleData['multipleFor3Props'];
              multipleFor4Props = singleData['multipleFor4Props'];
              multipleFor5Props = singleData['multipleFor5Props'];


              if (houseProp.half === 2) {
                let leagueType = houseProp['player1'].leagueType;

                const tempHouseProp = {
                  contestId: houseProp['contestId'],
                  minPropsToPick: houseProp['minPropsToPick'],
                  player1: {
                    firstName: houseProp['player1'].firstName,
                    height: houseProp['player1'].height,
                    image: houseProp['player1'].image,
                    lastName: houseProp['player1'].lastName,
                    leagueType: leagueType,
                    playerId: houseProp['player1'].playerId,
                    positionAbbreviation: houseProp['player1'].positionAbbreviation,
                    uniform: houseProp['player1'].uniform,
                    weight: houseProp['player1'].weight,
                    id: houseProp['player1'].id,
                    teamAbbr: houseProp['player1'].teamAbbr,
                    propParameters: houseProp['player1'].propParameters,
                  },
                  player1TeamId: houseProp.player1TeamId,
                  propId: houseProp.propId,
                  propValue: houseProp.propValue,
                  startTime: houseProp.startTime,
                  startDate: houseProp.startTime,
                  statEventId: houseProp.statEventId,
                  team1Abbr: houseProp.team1Abbr,
                  team2Abbr: houseProp.team2Abbr,
                  venueState: houseProp.venueState,
                  team1locationType: houseProp.team1locationType,
                  golfRound: houseProp.golfRound,
                  golfTeeTime: houseProp.golfTeeTime,
                  selected: false,
                  isOverSelected: false,
                  isUnderSelected: false,
                  isPromo: houseProp.isPromo
                }


                if (!allHousePropsMap.has(houseProp.propId)) {
                  allHousePropsMap.set(houseProp.propId, tempHouseProp)
                }

                if (!leagueTypeList.some(function (o) {return o['name'] === leagueType;})) {
                  leagueTypeList.push({
                    name: leagueType,
                  });
                }
                currentlyShowingHousePropsMap.set(houseProp.propId, tempHouseProp)
              }
            });

            this.setState({
              allHousePropsMap: allHousePropsMap,
              currentlyShowingHousePropsMap: currentlyShowingHousePropsMap,
              multipleFor2Props: multipleFor2Props,
              multipleFor3Props: multipleFor3Props,
              multipleFor4Props: multipleFor4Props,
              multipleFor5Props: multipleFor5Props,

              leagueTypeList: leagueTypeList,
            });
            $('.loader').removeClass('show');
          } else {
            $('.loader').removeClass('show');
          }
        });
  }

  pointsSelect(propId, type) {
    const numberOfPropsPicked = this.state.numberOfPropsPicked;
    const maximumPicks = this.state.maximumPicks;
    const allHousePropsMap = this.state.allHousePropsMap

    if (type === 'over') {
      Mixpanel.track('web:user_selected_over', {
        'Prop Id': propId,
      });
    } else if (type === 'under') {
      Mixpanel.track('web:user_selected_under', {
        'Prop Id': propId,
      });
    }

    if (!allHousePropsMap.get(propId)['selected'] && numberOfPropsPicked >= maximumPicks) {
      messages('error', 'You have exceeded maximum 5 props limit');
      return;
    }

    this.highlightButtonNEW(propId, type);
  }

  highlightButtonNEW(propId, type) {
    let numberOfPropsPicked = this.state.numberOfPropsPicked;
    const allHousePropsMap = this.state.allHousePropsMap

    if (!allHousePropsMap.get(propId).isUnderSelected && !allHousePropsMap.get(propId).isOverSelected) {
      numberOfPropsPicked++;
    }
    if (type === 'over') {
      allHousePropsMap.get(propId).isOverSelected = !allHousePropsMap.get(propId).isOverSelected;
      allHousePropsMap.get(propId).isUnderSelected = false;
    } else if (type === 'under') {
      allHousePropsMap.get(propId).isUnderSelected =
          !allHousePropsMap.get(propId).isUnderSelected;
      allHousePropsMap.get(propId).isOverSelected = false;
    } else if (type === '') {
      allHousePropsMap.get(propId).isUnderSelected = false;
      allHousePropsMap.get(propId).isOverSelected = false;
    }
    if (allHousePropsMap.get(propId).isUnderSelected || allHousePropsMap.get(propId).isOverSelected) {
      allHousePropsMap.get(propId)['selected'] = true;
    } else {
      numberOfPropsPicked--;
      allHousePropsMap.get(propId).selected = false;
    }

    this.setState({
      allHousePropsMap: allHousePropsMap,
      numberOfPropsPicked: numberOfPropsPicked,
    });
  }

  getSelectedHouseProps() {
    let allHousePropsMap = this.state.allHousePropsMap;
    let selectedHouseProps = [];

    for (const houseProp of allHousePropsMap.values()) {
      if (houseProp['selected']) {
        selectedHouseProps.push(houseProp);
      }
    }

    return selectedHouseProps;
  }

  componentDidMount() {
    isAuthenticated('login', '/props-lobby');

    $('.loader').addClass('show');

    let latitude = window.localStorage.getItem('latitude')
    let longitude = window.localStorage.getItem('longitude')
    console.log('window.localStorage latitude, longitude: ',latitude,',',longitude,' from componentDidMount')
    if (latitude === null || longitude === null) {
      console.log('Begin Fetching Location from componentDidMount')
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('End Fetching Location from componentDidMount')

        latitude = position.coords.latitude + ''
        longitude = position.coords.longitude + ''

        let requestUpcomingHousePropsBody = {
          currentPage: 1,
          currentSize: 100,
          half: 0,
          Latitude: latitude,
          Longitude: longitude,
        };

        const requestUpcomingHousePropsOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Token: window.localStorage.getItem('userData')
                ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
                : false,
          },
          body: JSON.stringify(requestUpcomingHousePropsBody),
        };

        window.localStorage.setItem('latitude', latitude);
        window.localStorage.setItem('longitude', longitude);

        this.fetchUpcomingHousePropsByHalf(requestUpcomingHousePropsOptions)
      });
    } else {
      console.log('latitude and longitude already in window.localStorage from componentDidMount')
      let requestUpcomingHousePropsBody = {
        currentPage: 1,
        currentSize: 100,
        half: 0,
        Latitude: latitude,
        Longitude: longitude,
      };

      const requestUpcomingHousePropsOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Token: window.localStorage.getItem('userData')
              ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
              : false,
        },
        body: JSON.stringify(requestUpcomingHousePropsBody),
      };

      this.fetchUpcomingHousePropsByHalf(requestUpcomingHousePropsOptions)
    }


    Mixpanel.track('web:props_lobby_opened');
  }

  exportPropsPicks() {
    $('.loader').addClass('show');
    const ExportPicksOptions = {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Token: window.localStorage.getItem('userData')
            ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
            : false,
      },
    };

    const { saveAsCsv } = useJsonToCsv();
    fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/get-csv-upcoming-house-props', ExportPicksOptions)
        .then((response) => response.json())
        .then((jsonData) => {
          if (jsonData['success']) {
            if (jsonData['response']['model']['csvData'].length) {
              const data = jsonData['response']['model']['csvData'];
              const fields = toObject(
                  data,
                  jsonData['response']['model']['csvHeader']
              );
              const filename = 'Picks';
              saveAsCsv({ fields, data, filename });
            } else {
              messages('error', 'No Data Available!');
            }
            $('.loader').removeClass('show');
          } else {
            $('.loader').removeClass('show');
            messages('error', jsonData['message']);
          }
        });

    Mixpanel.track('web:user_exported_prop_picks');
  }

  clearAllPropsPicksSelectionNEW() {
    this.setState({
      // selectedPlayerIds: [],
      // selectedPropIds: [],
    });
    let allHousePropsMap = this.state.allHousePropsMap;

    for (const houseProp of allHousePropsMap.values()) {
      houseProp['selected'] = false
      houseProp['isUnderSelected'] = false;
      houseProp['isOverSelected'] = false;
    }


    this.setState({
      // allOfTheProps: allOfTheProps,
      allHousePropsMap: allHousePropsMap,
      numberOfPropsPicked: 0,
    });

    Mixpanel.track('web:user_cleared_prop_selection');
  }

  submitPropsPicks() {
    const numberOfPropsPicked = this.state.numberOfPropsPicked;
    const minimumPicks = this.state.minimumPicks;

    if (numberOfPropsPicked < minimumPicks) {
      let remaining = minimumPicks - numberOfPropsPicked;
      messages('error', 'Please select ' + remaining + ' more prop');
      return;
    }

    this.getUserHouseTickets();

    const multipleFor2Props = this.state.multipleFor2Props;
    const multipleFor3Props = this.state.multipleFor3Props;
    const multipleFor4Props = this.state.multipleFor4Props;
    const multipleFor5Props = this.state.multipleFor5Props;

    Mixpanel.track('web:user_clicked_submit_props');

    let multiplier = multipleFor2Props;
    if (numberOfPropsPicked === 3) {
      multiplier = multipleFor3Props
    } else if (numberOfPropsPicked === 4) {
      multiplier = multipleFor4Props
    } else if (numberOfPropsPicked === 5) {
      multiplier = multipleFor5Props
    }
    this.setState({
      // selectedHouseProps: selectedHouseProps,
      multiplier: multiplier
    });

    $('.pay-modal').addClass('show');
    $('.number-of-entries').val('1');
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    value = value.replace(/[^0-9]+/g, '');
    if (value.length >= 4) {
      value = value.slice(0, 4);
    }

    $('.number-of-entries').val(value);

    this.setState({
      [name]: value,
    });
  }

  /*Close Pay popup */
  closePayModal() {
    $('.pay-modal').removeClass('show');
  }

  clickClosePayModal() {
    $('.pay-modal').removeClass('show');

    Mixpanel.track('web:user_closed_pay_modal');
  }

  /*Validate picks and pay */
  pay() {
    $('.loader').addClass('show');
    // let allOfTheProps = this.state.allOfTheProps
    let allHousePropsMap = this.state.allHousePropsMap
    let selectedHouseProps = [];

    for (const [propId, houseProp] of allHousePropsMap) {
      if (houseProp['selected']) {
        selectedHouseProps.push({
          id: propId,
          isOver: houseProp['isOverSelected']
        })
      }
    }

    let houseUserContestData = {
      entryAmount: this.state.entryAmount,
      possibleWinnings:
          this.state.entryAmount * this.state.multiplier +
          this.state.entryAmount / 1,
      houseProps: selectedHouseProps,
    };

    const getWalletBalanceOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "X-User-Id": window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData")).userDto.userid
          : false,
        Token: window.localStorage.getItem('userData')
            ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
            : false,
      },
    };

    Mixpanel.track('web:user_clicked_pay_now');

    fetch(process.env.REACT_APP_SERVER_URL + 'users/get-wallet-balance', getWalletBalanceOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {
            const walletBalance = data['response']['walletBalance'];
            if (walletBalance < this.state.entryAmount) {
              $('.loader').removeClass('show');
              messages(
                  'error',
                  'You have insufficient balance to withdraw your balance'
              );
            } else {
              const getPropsPaymentOptions = {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  Token: window.localStorage.getItem('userData')
                      ? JSON.parse(window.localStorage.getItem('userData'))[
                          'accessToken'
                          ]
                      : false,
                },
                body: JSON.stringify(houseUserContestData),
              };
              fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/submitHouseContestEntry', getPropsPaymentOptions)
                  .then((response) => response.json())
                  .then((data) => {
                    if (data['success']) {
                      //nelsonga - house
                      if(window.dataLayer) {
                        window.dataLayer.push({
                          'event': 'Prop_Bet_Success'
                        });
                      }

                      console.log("omgomgnelson -------")
                      

                      messages('success', data['message']);
                      this.closePayModal();
                      this.clearAllPropsPicksSelectionNEW();
                      $('.user-wallet').html(
                          '$' + walletBalance - this.state.entryAmount
                      );
                      this.setState({
                        entryAmount: 1
                      })
                      window.scrollTo(0, 0);
                      $('.loader').removeClass('show');
                    } else {
                      messages('error', data['message']);
                      $('.loader').removeClass('show');
                    }
                  });
            }
          } else {
            messages('error', data['message']);
            $('.loader').removeClass('show');
          }
        });
  }

  tabSwitch(event) {
    $('.loader').addClass('show');

    const tab = event.target.getAttribute('rel');
    $('.tabs-heads').find('button').removeClass('active');
    event.target.classList.add('active');
    $('.tabs-body').find('div').removeClass('active');
    $('.tabs-body').find('div[rel="' + tab + '"]').addClass('active');

    var half = 0

    if (tab === '1') {
      half = 1
    } else if (tab === '2') {
      half = 2
    }
    let latitude = window.localStorage.getItem('latitude')
    let longitude = window.localStorage.getItem('longitude')
    console.log('window.localStorage latitude, longitude: ',latitude,',',longitude,' from tabSwitch')
    let requestUpcomingHousePropsBody = {
      currentPage: 1,
      currentSize: 100,
      half: half,
      Latitude: latitude,
      Longitude: longitude,
    };

    const requestUpcomingHousePropsOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Token: window.localStorage.getItem('userData')
            ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
            : false,
      },
      body: JSON.stringify(requestUpcomingHousePropsBody),
    };


    if (half === 0) {
      if (latitude === null || longitude === null) {
        console.log('Begin Fetching Location from tabSwitch')
        navigator.geolocation.getCurrentPosition((position) => {
          console.log('End Fetching Location from tabSwitch')
          latitude = position.coords.latitude + ''
          longitude = position.coords.longitude + ''

          requestUpcomingHousePropsBody['Latitude'] = latitude
          requestUpcomingHousePropsBody['Longitude'] = longitude

          window.localStorage.setItem('latitude', latitude)
          window.localStorage.setItem('longitude', longitude)

          requestUpcomingHousePropsOptions['body'] = JSON.stringify(requestUpcomingHousePropsBody)

          this.fetchUpcomingHousePropsByHalf(requestUpcomingHousePropsOptions)
        });
      } else {
        console.log('latitude and longitude already in window.localStorage from tabSwitch')
        requestUpcomingHousePropsBody['Latitude'] = latitude
        requestUpcomingHousePropsBody['Longitude'] = longitude

        requestUpcomingHousePropsOptions['body'] = JSON.stringify(requestUpcomingHousePropsBody)

        this.fetchUpcomingHousePropsByHalf(requestUpcomingHousePropsOptions)
      }
    } else {
      console.log('Not Fetching Location from tabSwitch because half: ',half)
      this.fetchUpcomingHousePropsByHalf(requestUpcomingHousePropsOptions)
    }

    this.setState({
      gameList: [],
      propsList: [],
      selectedSport: null,
      selectedGame: null,
      selectedProp: null,
      toggle: false,
      gameToggle: false,
      propToggle: false
    });

    Mixpanel.track('web:user_switched_prop_lobby_tab', {
      Tab: tab,
    });
  }

  triggerPlayerStatsPopup(player) {
    this.playerStats.current.openPlayerStatsPopup(player);

    Mixpanel.track('web:user_triggered_player_stats_popup', {
      'Player Id': player.id,
    });
  }

  numSuffix(n) {
    n = parseInt(n);
    if (isNaN(n)) {
      n = 0;
    }
    const suffix =
        ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
    return n + suffix;
  }

  getGame(houseProp) {
    if (houseProp.player1.leagueType !== 'GOLF') {
      // var ts = houseProp.startTime.slice(houseProp.startTime.length - 5);
      // var H = +ts.substr(0, 2);
      // var h = H % 12 || 12;
      // h = h - 4;
      // h = h < 10 ? '0' + h : h;
      // // var ampm = H < 12 ? " AM" : " PM";
      // ts = h + ts.substr(2, 3); // + ampm;
      if(houseProp.player1.leagueType !== "UFC") {
        let game = houseProp.team1Abbr.toUpperCase() + " v " + houseProp.team2Abbr.toUpperCase(); //+ " (" + ts + ')';
        return game;
      }
      else {
        let game = houseProp.team1Name + ' vs. ' + houseProp.team2Name
        return game;
      }
//       let game = houseProp.team1Abbr.toUpperCase() + ' v ' + houseProp.team2Abbr.toUpperCase(); // + " (" + ts + ')';
    }
  }

  getPropParameter(houseProp) {


    const propParameter = houseProp.player1.propParameters.join(' + ')

    return propParameter;
  }

  checkEligibleHouseTicket() {
    const eligibleHouseTicketOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Token: window.localStorage.getItem('userData')
            ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
            : false,
      },
    };
    fetch(process.env.REACT_APP_SERVER_URL + 'tickets/getEligibleHouseTicketForPropLobby', eligibleHouseTicketOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {
            console.log(data['response']);
            // this.setState({ houseTickets: data['response'] });
            if (data['response'] != null) {
              this.setState({ hasEligibleHouseTicket: true });
            }
          } else {
            // this.setState({ houseTickets: [] });
          }
        })
        .catch((error) => {
          messages('error', error);
        });
  }

  getUserHouseTickets() {
    const ticketRequestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "X-User-Id": window.localStorage.getItem("userData")
          ? JSON.parse(window.localStorage.getItem("userData")).userDto.userid
          : false,
        Token: window.localStorage.getItem('userData')
            ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
            : false,
      },
    };

    $('.loader').addClass('show');
    fetch(process.env.REACT_APP_SERVER_URL + 'tickets/getUsersHouseTickets', ticketRequestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {
            console.log(data['response']);
            this.setState({ houseTickets: data['response'] });
            // this.presentEnterContestModal();
          } else {
            this.setState({ houseTickets: [] });
            // this.presentEnterContestModal();
          }
          $('.loader').removeClass('show');
        })
        .catch((error) => {
          $('.loader').removeClass('show');
          messages('error', error);
        });
  }

  listHouseTicketInfo(houseTicket) {
    if (houseTicket == null) {
      return null;
    }

    return (
        <ul>
          <li>
            <span className='houseTicketLabels'>Dollar Value: </span>
            <span className='houseTicketValues'>
            ${this.getHouseTicketDollarAmount(houseTicket)}
          </span>
          </li>
          <li>
            <span className='houseTicketLabels'>Expiration Date: </span>
            <span className='houseTicketValues'>
            {this.getHouseTicketExpirationDate(houseTicket)}
          </span>
          </li>
          <li>
            <span className='houseTicketLabels'>Total Payments: </span>
            <span className='houseTicketValues'>
            {this.getHouseTicketPayOut(houseTicket)}
          </span>
          </li>
          <span>
          <button
              onClick={() => {
                this.enterWithHouseTicket(houseTicket);
              }}
              className='pay-now'
          >
            Use Ticket
          </button>
        </span>
        </ul>
    );
  }

  getHouseTicketDollarAmount(houseTicket) {
    if (houseTicket === null) {
      return null;
    }
    return houseTicket['dollarAmount'];
  }

  getHouseTicketExpirationDate(houseTicket) {
    if (houseTicket === null) {
      return null;
    }
    return new Date(houseTicket['expirationDate']).toLocaleDateString();
  }

  getHouseTicketPayOut(houseTicket) {
    if (houseTicket === null) {
      return null;
    }
    return formatNumber(
        houseTicket['dollarAmount'] * this.state.multiplier +
        houseTicket['dollarAmount'] / 1
    );
  }

  enterWithHouseTicket(houseTicket) {
    if (houseTicket === null) {
      return;
    }

    const allHousePropsMap = this.state.allHousePropsMap;
    let selectedHouseProps = []

    for (const [propId, houseProp] of allHousePropsMap) {
      if (houseProp['selected']) {
        selectedHouseProps.push({
          id: propId,
          isOver: houseProp['isOverSelected']
        })
      }
    }


    let houseUserContestData = {
      entryAmount: houseTicket['dollarAmount'],
      possibleWinnings:
          houseTicket['dollarAmount'] * this.state.multiplier +
          houseTicket['dollarAmount'] / 1,
      houseProps: selectedHouseProps,
      houseTicketId: houseTicket['id'],
    };

    const getPropsPaymentOptions = {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Token: window.localStorage.getItem('userData')
            ? JSON.parse(window.localStorage.getItem('userData'))['accessToken']
            : false,
      },
      body: JSON.stringify(houseUserContestData),
    };

    fetch(process.env.REACT_APP_SERVER_URL + 'houseProp/submitHousePropsWithTicket/', getPropsPaymentOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data['success']) {
            //nelsonga - house
            if (window.dataLayer) {
              window.dataLayer.push({
                'event': 'Prop_Bet_Success'
              });
            }

            messages('success', data['message']);
            this.props.history.push(
                process.env.REACT_APP_START_URL + 'contest-lobby'
            );
          } else {
            messages('error', data['message']);
            $('.loader').removeClass('show');
          }
          $('.loader').removeClass('show');
        });
  }

  getSelectedSubmissionOdds() {
    switch (this.getSelectedHouseProps().length) {
      case 2:
        return this.getSelectedHouseProps().length + ' (' + (this.state.multipleFor2Props+1) + 'x - 1)'
      case 3:
        return this.getSelectedHouseProps().length + ' (' + (this.state.multipleFor3Props+1) + 'x - 1)'
      case 4:
        return this.getSelectedHouseProps().length + ' (' + (this.state.multipleFor4Props+1) + 'x - 1)'
      case 5:
        return this.getSelectedHouseProps().length + ' (' + (this.state.multipleFor5Props+1) + 'x - 1)'
      default:
        return '';
    }
  }
  
  getSubmissionOddsWithNumberOfProps(numberOfProps) {
    switch (numberOfProps) {
      case 2:
        return this.state.multipleFor2Props+1
      case 3:
        return this.state.multipleFor3Props+1
      case 4:
        return this.state.multipleFor4Props+1
      case 5:
        return this.state.multipleFor5Props+1
      default:
        return '0';
    }
  }

  getPropStyling(houseProp) {
    if(houseProp.player1.leagueType !== "UFC") {
      return <h3>
        {houseProp.player1.firstName}{" "}{houseProp.player1.lastName}
        {houseProp.team1locationType === "home" ? (
            <span>
                {houseProp.player1.positionAbbreviation}-{houseProp.player1.teamAbbr} |{" "}
              {houseProp.team2Abbr} @{" "}
              {houseProp.team1Abbr}
              </span>
        ) : (
            ""
        )}
        {houseProp.team1locationType ===
        "away" ? (
            <span>
                {
                  houseProp.player1
                      .positionAbbreviation
                }
              -{houseProp.player1.teamAbbr} |{" "}
              {houseProp.team1Abbr} @{" "}
              {houseProp.team2Abbr}
              </span>
        ) : (
            ""
        )}
      </h3>
    }
    else {
      let uniform = houseProp.player1.uniform !== null ? parseInt(houseProp.player1.uniform) : null
      if(uniform !== null && uniform !== 0) {
        return <h3>{houseProp.player1.firstName}{" "}{houseProp.player1.lastName}<span>#{houseProp.player1.uniform} {houseProp.player1.positionAbbreviation} | {houseProp.team1Name} vs. {houseProp.team2Name}</span></h3>
      }
      else if(uniform === null) {
        return <h3>{houseProp.player1.firstName}{" "}{houseProp.player1.lastName}<span>{houseProp.player1.positionAbbreviation} | {houseProp.team1Name} vs. {houseProp.team2Name}</span></h3>
      }
      else if(uniform === 0) {
        return <h3>{houseProp.player1.firstName}{" "}{houseProp.player1.lastName}<span><img src={beltImage} style={{width: '30px', marginRight: '5px', marginTop: '5px'}}/> {houseProp.player1.positionAbbreviation} | {houseProp.team1Name} vs. {houseProp.team2Name}</span></h3>
      }
    }
  }

  render() {
    // const allOfTheProps = this.state.allOfTheProps;
    //nelsonnew
    const allHousePropsMap = this.state.allHousePropsMap;
    // const propsList = this.state.propsList;
    const currentlyShowingHousePropsMap = this.state.currentlyShowingHousePropsMap
    const selectedSport = this.state.selectedSport;
    const selectedGame = this.state.selectedGame;
    const selectedProp = this.state.selectedProp;
    const leagueTypeList = this.state.leagueTypeList;
    const gameList = this.state.gameList;
    const propsList = this.state.propsList;

    leagueTypeList.some(function (o) {
      if(o['name'] === "HOCKEY") {
        o['name'] = "NHL"
      }
    })

    var sportsMenu = Menu(leagueTypeList);
    var gameMenu = Menu(gameList);
    let propsMenu = Menu(propsList);

    var selectedHouseProps = this.getSelectedHouseProps()

    return (
        <main className='props-lobby-page'>
          <LoggedInHeader />
          <div className='inner'>
            <aside>
              <div className="event-summary">
                <h2>Select The Props</h2>
                <p>
                  Select two, three, four, or five players to create a lineup from the
                  list of player predictions.
                  <br />
                  <br />
                  Choose your entry amount from $1 - $1000:
                  <ul>
                    {[2, 3, 4, 5].map(num => (
                      <li>Pick {num}/{num} correctly, win {this.getSubmissionOddsWithNumberOfProps(num)}x your entry amount.</li>
                    ))}
                  </ul>
                  <br />
                  Must choose athletes from more than one team to be able to
                  enter. All submissions are final. If any athlete does not play,
                  the full entry will be canceled and the fee will be credited
                  back to your account. MLB Players and NHL Goalies must start to qualify.
                  <br />
                  <br />
                  If any prop has been incorrectly posted, ThriveFantasy has the
                  right to cancel the entry and refund all submissions that
                  include that prop.
                  <br />
                  <br />
                  <p className="promo-styling">
                    Max $25 in total entry fees allowed to be placed with the Promotional Pick included. Promotional Picks cannot be combined.
                  </p>
                </p>
              </div>


              <ul className='listing'>
                {selectedHouseProps.length > 0 ? (
                    <h1 style={{ color: '#0CBCED', fontSize: '22px' }}>
                      Props Selected: {this.getSelectedSubmissionOdds()}
                    </h1>
                ) : (
                    ''
                )}
                {selectedHouseProps.length > 0
                    ? selectedHouseProps.map((pick) => (
                        <div>
                          <div>
                            <li key={pick.propId}>
                              <div
                                  className='player'
                                  onClick={() =>
                                      this.triggerPlayerStatsPopup(pick.player1)
                                  }
                              >
                                <img
                                    src={pick.player1.image}
                                    alt={pick.player1.firstName}
                                />
                                {pick.player1.leagueType === 'GOLF' ? (
                                    <h3>
                                      {pick.player1.firstName} {pick.player1.lastName}
                                      <h4>
                                        {this.numSuffix(
                                            pick.golfRound ? pick.golfRound : 0
                                        )}{' '}
                                        RND
                                      </h4>
                                    </h3>
                                ) : this.getPropStyling(pick)
                                }
                              </div>
                              <div className='rightSide'>
                                {allHousePropsMap.get(pick.propId)
                                    .isOverSelected === true ? (
                                    <div>
                                      <h3 className='OU'>More</h3>{' '}
                                      <h3>{pick.propValue}</h3>
                                    </div>
                                ) : (
                                    <div>
                                      <h3 className='OU'>Less</h3>{' '}
                                      <h3>{pick.propValue} </h3>
                                    </div>
                                )}
                                <div className='text'>
                                  {pick.player1.propParameters.length ? (
                                      <span>
                                  {pick.player1.propParameters.map(
                                      (prop, index) => (
                                          <span key={prop}>
                                        {index > 0 ? ' + ' : ''}
                                            {prop}
                                      </span>
                                      )
                                  )}
                                </span>
                                  ) : (
                                      ''
                                  )}
                                </div>
                                <button
                                    className='close-player-popup'
                                    onClick={() =>
                                        this.highlightButtonNEW(pick['propId'], '')
                                    }
                                ></button>
                              </div>
                            </li>
                          </div>
                        </div>
                    ))
                    : ''}
                {selectedHouseProps.length >= 2 ? (
                    <button
                        className='submit-button'
                        onClick={this.submitPropsPicks}
                    >
                      Submit
                    </button>
                ) : (
                    ''
                )}
              </ul>
            </aside>
            <section className='event-props'>
              {/* {selectedHouseProps.length > 0 ? ( */}
                  <div className='actions'>


                    {/* {selectedHouseProps.length > 0 ? ( */}
                    {/* indent the below by 1 */}
                    <div className='left'>
                      <button
                          onClick={() => this.clearAllPropsPicksSelectionNEW()}
                          className='clear-selection'
                          title='Clear Selection'
                      >
                        Clear Selection
                      </button>
                    </div>
                    <div className='right'>
                      <button onClick={() => this.exportPropsPicks()} className='export-picks' title='Export Picks'>
                        Export Picks
                      </button>
                    </div>
                  </div>
              {/* ) : (
                  ''
              )} */}
              {currentlyShowingHousePropsMap.size > 0 ? (
                  <div className='tabs-container-sport'>
                    <div className='tabs-heads-sport'>
                      <ScrollMenu
                          alignCenter={false}
                          data={sportsMenu}
                          arrowLeft={ArrowLeft}
                          arrowRight={ArrowRight}
                          onSelect={this.onSelectSport}
                          wheel={false}
                          itemClassActive={
                            this.state.toggle === true ? 'active' : 'inactive'
                          }
                      />
                    </div>
                  </div>
               ) : (
                  ''
              )} 
              {gameList.length > 0 ? (
                  <div className='tabs-container-game'>
                    <div className='tabs-heads-game'>
                      <ScrollMenu
                          alignCenter={false}
                          data={gameMenu}
                          arrowLeft={ArrowLeft}
                          arrowRight={ArrowRight}
                          onSelect={this.onSelectGame}
                          wheel={false}
                          itemClassActive={
                            this.state.gameToggle === true ? 'active' : 'inactive'
                          }
                      />
                    </div>
                  </div>
              ) : (
                  ''
              )}
              {propsList.length > 0 ? (
                  <div className='tabs-container-prop'>
                    <div className='tabs-heads-prop'>
                      <ScrollMenu
                          alignCenter={false}
                          data={propsMenu}
                          arrowLeft={ArrowLeft}
                          arrowRight={ArrowRight}
                          onSelect={this.onSelectProp}
                          wheel={false}
                          itemClassActive={
                            this.state.propToggle === true ? 'active' : 'inactive'
                          }
                      />
                    </div>
                  </div>
              ) : (
                  ''
              )}
              <div className='tabs-container'>
                <div className='tabs-heads'>
                  <button onClick={this.tabSwitch} className='active' rel='0'>
                    Full Game
                  </button>
                  <button onClick={this.tabSwitch} rel='1'>
                    1st Half
                  </button>
                  <button onClick={this.tabSwitch} rel='2'>
                    2nd Half
                  </button>
                </div>
                {currentlyShowingHousePropsMap.size > 0 ? (
                    <div className='tabs-body'>
                      <div rel='0' className='tab-making-picks-props-listing active'>
                        <div className='head'>
                          <span>Player</span>
                          <span>Props</span>
                          <span>More / Less</span>
                        </div>
                        <ul className='listing'>


                          {Array.from(currentlyShowingHousePropsMap).map( ([propId, houseProp]) =>
                              <div>{/*nelsonhere11*/}


                                {selectedSport === null ||
                                (houseProp.player1.leagueType === selectedSport &&
                                    (selectedProp !== null ? this.getPropParameter(houseProp) === selectedProp : true)) ? (
                                    <div>
                                      {houseProp.player1.leagueType === 'GOLF' ||
                                      (selectedSport !== null &&
                                          selectedGame ===
                                          this.getGame(houseProp)) ||
                                      selectedSport === null ||
                                      (selectedSport === houseProp.player1.leagueType && selectedGame === null) ? (
                                          <div>
                                            {houseProp.isPromo === false || houseProp.isPromo === null ? (
                                                <li key={propId}>
                                                  <div className='player' onClick={() => this.triggerPlayerStatsPopup(houseProp.player1)}>
                                                    <img src={houseProp.player1.image} alt={houseProp.player1.firstName}/>
                                                    {houseProp.player1.leagueType === 'GOLF' ? (

                                                        <h3>{houseProp.player1.firstName}{' '}{houseProp.player1.lastName}
                                                          <h4>{this.numSuffix(houseProp.golfRound ? houseProp.golfRound : 0)}{' '}RND</h4>
                                                        </h3>
                                                    ) : this.getPropStyling(houseProp)}
                                                    {houseProp.player1.leagueType === 'GOLF' ? (
                                                            <h4>Tee Time{' '}
                                                              <Moment utc local format='hh:mm a'>{houseProp.golfTeeTime}</Moment>,
                                                              {' '}
                                                              <Moment utc local format='ddd, MMM Do'>{houseProp.golfTeeTime}</Moment>
                                                            </h4>
                                                        ) :
                                                        (
                                                            <h4>
                                                              <Moment utc local format='hh:mm a'>{houseProp.startTime}</Moment>
                                                              ,{' '}
                                                              <Moment utc local format='ddd, MMM Do, YYYY'>{houseProp.startDate}</Moment>
                                                            </h4>
                                                        )
                                                    }
                                                  </div>
                                                  <div className='props'>
                                                    <h3>
                                                      <span className='prop-value'>
                                                        {houseProp.propValue}
                                                      </span>{' '}Total <br/>{houseProp.player1.propParameters.length ? (
                                                          <span>({houseProp.player1.propParameters.map((prop, index) => (
                                                              <span className='prop' key={prop}>
                                                                {index > 0 ? ' + ' : ''}{prop}
                                                              </span>
                                                                ))})
                                                      </span>
                                                      ) : ('')}
                                                    </h3>
                                                  </div>
                                                  <div className='points'>
                                                    <button className={allHousePropsMap.get(propId).isOverSelected === true ? 'active' : ''} onClick={() => this.pointsSelect(propId,'over')}>
                                                      More
                                                    </button>
                                                    <button className={allHousePropsMap.get(propId).isUnderSelected === true ? 'active' : ''} onClick={() => this.pointsSelect(propId, 'under')}>
                                                      Less
                                                    </button>
                                                  </div>
                                                </li>
                                            ) : (
                                                <div>
                                                  <p className={"promoText"}>PROMO</p>
                                                  <p className={"promoTextLeft"}> PROMO </p>
                                                  <li className={"promoProps"} key={houseProp.propId}>
                                                    <div className="player" onClick={() => this.triggerPlayerStatsPopup(houseProp.player1)}>
                                                      <img src={houseProp.player1.image} alt={houseProp.player1.firstName}/>
                                                      {houseProp.player1.leagueType ===
                                                      "GOLF" ? (
                                                          <h3>
                                                            {houseProp.player1.firstName}{" "}
                                                            {houseProp.player1.lastName}
                                                            <h4>
                                                              {this.numSuffix(
                                                                  houseProp.golfRound
                                                                      ? houseProp.golfRound
                                                                      : 0
                                                              )}{" "}
                                                              RND
                                                            </h4>
                                                          </h3>
                                                      ) : this.getPropStyling(houseProp)}
                                                      {houseProp.player1.leagueType ===
                                                      "GOLF" ? (
                                                          <h4>
                                                            Tee Time{" "}
                                                            <Moment utc local format="hh:mm a">{houseProp.golfTeeTime}</Moment>
                                                            ,{" "}
                                                            <Moment utc local format="ddd, MMM Do">{houseProp.golfTeeTime}</Moment>
                                                          </h4>
                                                      ) : (
                                                          <h4>
                                                            <Moment utc local format="hh:mm a">{houseProp.startTime}</Moment>
                                                            ,{" "}
                                                            <Moment utc local format="ddd, MMM Do, YYYY">{houseProp.startDate}</Moment>
                                                          </h4>
                                                      )}
                                                    </div>
                                                    <div className="props">
                                                      <h3>
                                                <span className="prop-value">
                                                  {houseProp.propValue}
                                                </span>{" "}
                                                        Total <br/>
                                                        {houseProp.player1.propParameters
                                                            .length ? (
                                                            <span>
                                                    ({houseProp.player1.propParameters.map((prop, index) => (
                                                                <span className="prop" key={prop}>
                                                          {index > 0 ? " + " : ""}{prop}
                                                        </span>))})
                                                  </span>) : ("")}
                                                      </h3>
                                                    </div>
                                                    <div className="points">
                                                      <button
                                                          className={
                                                            allHousePropsMap.get(propId)
                                                                .isOverSelected === true
                                                                ? "active"
                                                                : ""
                                                          }
                                                          onClick={() =>
                                                              this.pointsSelect(
                                                                  houseProp.propId,
                                                                  "over",
                                                                  houseProp.playerId
                                                              )
                                                          }
                                                      >
                                                        More
                                                      </button>
                                                      <button
                                                          className={
                                                            allHousePropsMap.get(propId)
                                                                .isUnderSelected === true
                                                                ? "active"
                                                                : ""
                                                          }
                                                          onClick={() =>
                                                              this.pointsSelect(
                                                                  houseProp.propId,
                                                                  "under",
                                                                  houseProp.playerId
                                                              )
                                                          }
                                                      >
                                                        Less
                                                      </button>
                                                    </div>
                                                  </li>
                                                </div>
                                            )}
                                          </div>
                                      ) : (
                                          ''
                                      )}{' '}
                                    </div>
                                ) : (
                                    ''
                                )}{' '}
                              </div>
                          )}
                        </ul>
                      </div>
                      <div rel='1' className='tab-making-picks-props-listing'>
                        <div className='head'>
                          <span>Player</span>
                          <span>Props</span>
                          <span>More / Less</span>
                        </div>
                        <ul className='listing'>
                          {Array.from(currentlyShowingHousePropsMap).map( ([propId, houseProp]) =>
                              <div>{/*nelsonhere12*/}


                                {selectedSport === null || (houseProp.player1.leagueType === selectedSport && (selectedProp !== null ? this.getPropParameter(houseProp) === selectedProp : true)) ? (
                                    <div>
                                      {houseProp.player1.leagueType === 'GOLF' ||
                                      (selectedSport !== null && selectedGame === this.getGame(houseProp)) || selectedSport === null || (selectedSport === houseProp.player1.leagueType && selectedGame === null) ? (
                                          <li key={propId}>
                                            <div className='player' onClick={() => this.triggerPlayerStatsPopup(houseProp.player1)}>
                                              <img src={houseProp.player1.image} alt={houseProp.player1.firstName}/>
                                              {houseProp.player1.leagueType === 'GOLF' ? (
                                                  <h3>
                                                    {houseProp.player1.firstName}{' '}
                                                    {houseProp.player1.lastName}
                                                    {houseProp.golfTeeTime ? (
                                                        <h4>
                                                          {this.numSuffix(houseProp.golfRound ? houseProp.golfRound : 0)}{' '}
                                                          RND | Tee Time{' '}
                                                          <Moment utc local format='hh:mm a'>{houseProp.golfTeeTime}</Moment>
                                                          ,{' '}
                                                          <Moment utc local format='ddd, MMM Do'>{houseProp.golfTeeTime}</Moment>
                                                        </h4>
                                                    ) : (
                                                        <h4>{this.numSuffix(houseProp.golfRound ? houseProp.golfRound : 0)}{' '}RND</h4>
                                                    )}
                                                  </h3>
                                              ) : (
                                                  <h3>
                                                    {houseProp.player1.firstName}{' '}
                                                    {houseProp.player1.lastName}
                                                    {houseProp.team1locationType ===
                                                    'home' ? (
                                                        <span>
                                            {houseProp.player1.positionAbbreviation}
                                                          -{houseProp.player1.teamAbbr}|{' '}
                                                          {houseProp.team2Abbr} @{' '}
                                                          {houseProp.team1Abbr}
                                          </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {houseProp.team1locationType ===
                                                    'away' ? (
                                                        <span>
                                            {
                                              houseProp.player1
                                                  .positionAbbreviation
                                            }
                                                          -{houseProp.player1.teamAbbr}|{' '}
                                                          {houseProp.team1Abbr} @{' '}
                                                          {houseProp.team2Abbr}
                                          </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                  </h3>
                                              )}
                                              <h4>
                                                <Moment utc local format='hh:mm a'>
                                                  {houseProp.startTime}
                                                </Moment>
                                                ,{' '}
                                                <Moment
                                                    utc
                                                    local
                                                    format='ddd, MMM Do, YYYY'
                                                >
                                                  {houseProp.startDate}
                                                </Moment>
                                              </h4>
                                            </div>
                                            <div className='props'>
                                              <h3>
                                      <span className='prop-value'>
                                        {houseProp.propValue}
                                      </span>{' '}
                                                Total <br />
                                                {houseProp.player1.propParameters
                                                    .length ? (
                                                    <span>
                                          (
                                                      {houseProp.player1.propParameters.map(
                                                          (prop, index) => (
                                                              <span
                                                                  className='prop'
                                                                  key={prop}
                                                              >
                                                {index > 0 ? ' + ' : ''}
                                                                {prop}
                                              </span>
                                                          )
                                                      )}
                                                      )
                                        </span>
                                                ) : (
                                                    ''
                                                )}
                                              </h3>
                                            </div>
                                            <div className='points'>
                                              <button
                                                  className={
                                                    allHousePropsMap.get(propId)
                                                        .isOverSelected === true
                                                        ? 'active'
                                                        : ''
                                                  }
                                                  onClick={() =>
                                                      this.pointsSelect(
                                                          propId,
                                                          'over'
                                                      )
                                                  }
                                              >
                                                More
                                              </button>
                                              <button
                                                  className={
                                                    allHousePropsMap.get(propId)
                                                        .isUnderSelected === true
                                                        ? 'active'
                                                        : ''
                                                  }
                                                  onClick={() =>
                                                      this.pointsSelect(
                                                          propId,
                                                          'under'
                                                      )
                                                  }
                                              >
                                                Less
                                              </button>
                                            </div>
                                          </li>
                                      ) : (
                                          ''
                                      )}{' '}
                                    </div>
                                ) : (
                                    ''
                                )}{' '}
                              </div>
                          )}


                        </ul>
                      </div>
                      <div rel='2' className='tab-making-picks-props-listing'>
                        <div className='head'>
                          <span>Player</span>
                          <span>Props</span>
                          <span>More / Less</span>
                        </div>
                        <ul className='listing'>



                          {Array.from(currentlyShowingHousePropsMap).map( ([propId, houseProp]) =>
                              <div>{/*nelsonhere13*/}


                                {selectedSport === null ||
                                (houseProp.player1.leagueType === selectedSport &&
                                    (selectedProp !== null ? this.getPropParameter(houseProp) === selectedProp : true)) ? (


                                    <div>
                                      {houseProp.player1.leagueType === 'GOLF' ||
                                      (selectedSport !== null &&
                                          selectedGame ===
                                          this.getGame(houseProp)) ||
                                      selectedSport === null ||
                                      (selectedSport ===
                                          houseProp.player1.leagueType &&
                                          selectedGame === null) ? (
                                          <li key={propId}>
                                            <div
                                                className='player'
                                                onClick={() =>
                                                    this.triggerPlayerStatsPopup(
                                                        houseProp.player1
                                                    )
                                                }
                                            >
                                              <img
                                                  src={houseProp.player1.image}
                                                  alt={houseProp.player1.firstName}
                                              />
                                              {houseProp.player1.leagueType === 'GOLF' ? (
                                                  <h3>
                                                    {houseProp.player1.firstName}{' '}
                                                    {houseProp.player1.lastName}
                                                    {houseProp.golfTeeTime ? (
                                                        <h4>
                                                          {this.numSuffix(
                                                              houseProp.golfRound
                                                                  ? houseProp.golfRound
                                                                  : 0
                                                          )}{' '}
                                                          RND | Tee Time{' '}
                                                          <Moment
                                                              utc
                                                              local
                                                              format='hh:mm a'
                                                          >
                                                            {houseProp.golfTeeTime}
                                                          </Moment>
                                                          ,{' '}
                                                          <Moment
                                                              utc
                                                              local
                                                              format='ddd, MMM Do'
                                                          >
                                                            {houseProp.golfTeeTime}
                                                          </Moment>
                                                        </h4>
                                                    ) : (
                                                        <h4>
                                                          {this.numSuffix(
                                                              houseProp.golfRound
                                                                  ? houseProp.golfRound
                                                                  : 0
                                                          )}{' '}
                                                          RND
                                                        </h4>
                                                    )}
                                                  </h3>
                                              ) : (
                                                  <h3>
                                                    {houseProp.player1.firstName}{' '}
                                                    {houseProp.player1.lastName}
                                                    {houseProp.team1locationType ===
                                                    'home' ? (
                                                        <span>
                                            {
                                              houseProp.player1
                                                  .positionAbbreviation
                                            }
                                                          -{houseProp.player1.teamAbbr}|{' '}
                                                          {houseProp.team2Abbr} @{' '}
                                                          {houseProp.team1Abbr}
                                          </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                    {houseProp.team1locationType ===
                                                    'away' ? (
                                                        <span>
                                            {
                                              houseProp.player1
                                                  .positionAbbreviation
                                            }
                                                          -{houseProp.player1.teamAbbr}|{' '}
                                                          {houseProp.team1Abbr} @{' '}
                                                          {houseProp.team2Abbr}
                                          </span>
                                                    ) : (
                                                        ''
                                                    )}
                                                  </h3>
                                              )}
                                              <h4>
                                                <Moment utc local format='hh:mm a'>
                                                  {houseProp.startTime}
                                                </Moment>
                                                ,{' '}
                                                <Moment
                                                    utc
                                                    local
                                                    format='ddd, MMM Do, YYYY'
                                                >
                                                  {houseProp.startDate}
                                                </Moment>
                                              </h4>
                                            </div>
                                            <div className='props'>
                                              <h3>
                                      <span className='prop-value'>
                                        {houseProp.propValue}
                                      </span>{' '}
                                                Total <br />
                                                {houseProp.player1.propParameters
                                                    .length ? (
                                                    <span>
                                          (
                                                      {houseProp.player1.propParameters.map(
                                                          (prop, index) => (
                                                              <span
                                                                  className='prop'
                                                                  key={prop}
                                                              >
                                                {index > 0 ? ' + ' : ''}
                                                                {prop}
                                              </span>
                                                          )
                                                      )}
                                                      )
                                        </span>
                                                ) : (
                                                    ''
                                                )}
                                              </h3>
                                            </div>
                                            <div className='points'>
                                              <button
                                                  className={
                                                    allHousePropsMap.get(propId)
                                                        .isOverSelected === true
                                                        ? 'active'
                                                        : ''
                                                  }
                                                  onClick={() =>
                                                      this.pointsSelect(
                                                          propId,
                                                          'over'
                                                      )
                                                  }
                                              >
                                                More
                                              </button>
                                              <button
                                                  className={
                                                    allHousePropsMap.get(propId)
                                                        .isUnderSelected === true
                                                        ? 'active'
                                                        : ''
                                                  }
                                                  onClick={() =>
                                                      this.pointsSelect(
                                                          propId,
                                                          'under'
                                                      )
                                                  }
                                              >
                                                Less
                                              </button>
                                            </div>
                                          </li>
                                      ) : (
                                          ''
                                      )}{' '}
                                    </div>
                                ) : (
                                    ''
                                )}{' '}
                              </div>
                          )}


                        </ul>
                      </div>
                    </div>
                ) : (
                    <div className='no-results'>
                      <div></div>
                      <h3>No Upcoming House Props</h3>
                    </div>
                )}
                <div className='totals'>
                  <div className='inner-totals'>
                    <div>
                      Props Picked:{' '}
                      <span>{this.state.numberOfPropsPicked}/5</span>
                    </div>
                    <button onClick={this.submitPropsPicks}>Submit</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className='pay-modal popup'>
            <div className='inner'>
              <button
                  onClick={this.clickClosePayModal}
                  className='close-pay-modal'
                  title='Close'
              ></button>
              <h2>Enter Contest</h2>
              <div>
                <div>
                  <label>No. Props Entries</label>
                  <span>{this.state.numberOfPropsPicked}</span>
                </div>
                <div>
                  <label>Entry Amount ($)</label>
                  <input
                      onChange={this.handleInputChange}
                      name='entryAmount'
                      className='number-of-entries'
                      type='tel'
                      max='9999'
                  />
                </div>

                <div>
                  <label>Total Payments</label>
                  <span>
                  {formatNumber(
                      this.state.entryAmount * this.state.multiplier +
                      this.state.entryAmount / 1
                  )}
                </span>
                </div>
              </div>
              <button onClick={this.pay} className='pay-now' title='Pay Now'>
                Pay Now
              </button>
              <ul className='tickets-list'>
                {this.state.houseTickets.length > 0 ? (
                    <h4> House Ticket(s): </h4>
                ) : (
                    ''
                )}
                {this.state.houseTickets.map((houseTicket) => {
                  return this.listHouseTicketInfo(houseTicket);
                })}
              </ul>
            </div>
          </div>
          <PlayerStatsPopup ref={this.playerStats} />

          <Footer />
        </main>
    );
  }
}
function formatNumber(num) {
  return '$' + (num * 1).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function toObject(data, arr) {
  let count = 0;
  let fields = {};
  for (var key of Object.keys(data[0])) {
    fields[key] = arr[count];
    count++;
  }
  return fields;
}

export default withRouter(PropsLobby);
